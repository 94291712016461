<template>
  <b-modal
    ref="result-modal"
    no-close-on-backdrop
    hide-footer
    :title="title"
    @show="setModal"
    @hidden="resetModal"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group
        label="เลข 3 ตัว (เฉพาะตัวเลขเท่านั้น)"
      >
        <b-form-input
          placeholder="XXX"
          maxlength="3"
          size="lg"
          v-on:keydown="keypress"
          v-model="inputResult.three"
          class="text-primary"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="เลข 2 ตัว (เฉพาะตัวเลขเท่านั้น)"
      >
        <b-form-input
          placeholder="XX"
          maxlength="2"
          size="lg"
          v-on:keydown="keypress"
          v-model="inputResult.two"
          class="text-primary"
        ></b-form-input>
      </b-form-group>

      <div class="pt-3">
        <b-button type="submit" variant="success" block pill :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import LottoService from '@/services/lottoService'
import Swal from 'sweetalert2'

export default {
  name: 'ResultModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    date: {
      type: String,
      default: null
    },
    code: {
      type: String,
      default: null
    },
    result: {
      type: Object,
      default: () => {
        return {
          three: '',
          two: ''
        }
      }
    }
  },
  data() {
    return {
      isProcess: false,
      needReload: false,
      inputResult: {
        three: '',
        two: ''
      }
    }
  },
  computed: {
    title() {
      const sec = {
        'sec1': 'เช้า 11.45 น.',
        'sec2': 'บ่าย 16.25 น.'
      }[this.code]

      return `วันที่ ${this.date} (${sec})`
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    setModal() {
      this.inputResult = this.result
    },
    resetModal() {
      this.isProcess = false
      this.needReload = false
    },
    showModal() {
      this.$refs['result-modal'].show()
    },
    hideModal() {
      this.$refs['result-modal'].hide()
    },
    keypress(event) {
      if(!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab'].includes(event.key)) {
        event.preventDefault()
      }
    },
    handleSubmit() {
      this.isProcess = true
      LottoService.update(this.date, this.code, this.inputResult)
      .then((response)=>{
        if(response.success) {
          this.needReload = true
          this.hideModal()

          this.$notify({
            type: 'success',
            text: 'เพิ่มบันทึกผลแล้ว!'
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: e?.message || 'บันทึกผลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
