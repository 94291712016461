<template>
  <div class="card n-box mb-2">
    <div class="row no-gutters align-items-center">
      <div class="col-2 text-center">
        <strong class="text-info">{{ title }}</strong><br />
        <small>{{ time }} น.</small>
      </div>
      <div class="col-4 text-center">
        <small>3 ตัวบน</small><br />
        <h2 v-if="three" class="mb-0 text-success">{{ three }}</h2>
        <h2 v-else class="mb-0 text-secondary">XXX</h2>
      </div>
      <div class="col-4 text-center">
        <small>2 ตัวล่าง</small>
        <br />
        <h2 v-if="two" class="mb-0 text-success">{{ two }}</h2>
        <h2 v-else class="mb-0 text-secondary">XX</h2>
      </div>
      <div class="col-2 text-center">
        <button @click="edit" type="button" class="btn btn-sm btn-warning">แก้ไข</button>
      </div>
    </div>

    <ResultModal :code="code" :date="date" :result="result" :is-show="isShowModal" @close="closeModal" />
  </div>
</template>
<script>
import ResultModal from './ResultModal'

export default {
  components: {
    ResultModal
  },
  props: ['code', 'data'],
  data() {
    return {
      isShowModal: false
    }
  },
  computed: {
    title() {
      return {
        'sec1': 'เช้า',
        'sec2': 'บ่าย'
      }[this.code]
    },
    time() {
      return {
        'sec1': '11.45',
        'sec2': '16.25'
      }[this.code]
    },
    date() {
      return this.data.lottoDate
    },
    three() {
      return this.data?.[this.code].award.three || ''
    },
    two() {
      return this.data?.[this.code].award.two || ''
    },
    result() {
      return {
        three: this.three,
        two: this.two
      }
    }
  },
  methods: {
    edit() {
      this.isShowModal = true
    },
    closeModal(needReload) {
      this.isShowModal = false

      if(needReload) {
        this.$emit('reload')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.n-box {
  cursor: pointer;
}

.n-box:hover {
  background-color: rgba(0,73,152,.4);
}
</style>
